export const socialProfileLabels = {
    behance: 'Behance',
    github: 'GitHub',
    medium: 'Medium',
    linkedin: 'LinkedIn',
    mail: 'Mail',
    twitter: 'Twitter',
    mastodon: 'Mastodon',
    hashnode: 'Hashnode',
    devto: 'Dev.to',
    instagram: 'Instagram',
    youtube: 'YouTube',
    twitch: 'Twitch',
    gitlab: 'GitLab',
    stackoverflow: 'StackOverflow',
    buymeacoffee: 'BuyMeACoffee',
    discord: 'Discord',
    dribble: 'Dribble',
    goodreads: 'Goodreads',
    patreon: 'Patreon',
    reddit: 'Reddit',
};
