import React from "react";

import { Page } from '../components/Page';
import { Seo } from '../components/Seo';

import { AboutSection } from '../sections/About';
import { ArticlesSection } from '../sections/Articles';
//import { ContactSection } from '../sections/Contact';
import { HeroSection } from '../sections/Hero';
//import { InterestsSection } from '../sections/Interests';
//import { ProjectsSection } from '../sections/Projects';

export default function IndexPage() {
  return (
    <>
      <Seo title="gjdickens | Greg Dickens" />
      <Page>
        <HeroSection sectionId="hero" />
        <ArticlesSection sectionId="articles" heading="Latest Notes" sources={['Notebook']} />
        <AboutSection sectionId="about" heading="About Me" />
        {/*
        <InterestsSection sectionId="details" heading="Details" />
        <ProjectsSection sectionId="features" heading="Built-in Features" />
        <ContactSection sectionId="github" heading="Issues?" />
        */}
      </Page>
    </>
  );
}
